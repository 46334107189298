import React from 'react';

import {PrivacyPolicy} from "../../components/PrivacyPolicy";
import {EmbedLayout} from "../../containers/EmbedLayout";
import {PageProps} from "gatsby";

export type PrivacyProps = {} & PageProps;
export default function Privacy({...props}: PrivacyProps) {
    return <EmbedLayout {...props} meta={{title:'Privacy Policy'}}>
        <div className='p-m-3'>
            <PrivacyPolicy/>
        </div>
    </EmbedLayout>;
}
